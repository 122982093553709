import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  AccountLogoDto,
  apiDeleteAccountLogo,
  apiGetAccountLogos,
} from '~/api/accounts';
import { notify } from '@kyvg/vue3-notification';
import { NotificationType } from '~/components/notification';

export const useQuadrantsStore = defineStore('quadrants', () => {
  const { t } = useI18n();

  const selectedLogoId: Ref<string | number> = ref('');
  const logos = ref<AccountLogoDto[]>([]);
  const selectLogo = (id: string | number) => {
    selectedLogoId.value = id;
  };

  const getLogos = async () => {
    logos.value = await apiGetAccountLogos();
  };

  const deleteLogo = async (logoId: number | string) => {
    await apiDeleteAccountLogo(logoId);
    logos.value = logos.value.filter((logo) => logo.id !== logoId);
  };

  const getLogoById = (logoId: number | string) => {
    return logos.value.find(({ id }) => id === logoId)?.logoUrl;
  };

  const addLogo = (logo: AccountLogoDto) => {
    logos.value.push(logo);
  };

  return {
    logos,
    getLogos,
    selectLogo,
    selectedLogoId,
    getLogoById,
    deleteLogo,
    addLogo,
  };
});
